<template>
  <div>
    <!--      <IndexCom></IndexCom>-->
    <div class="ci-header">
      <Carousel v-model="value1" :dots="indexList.config.length>1?'inside':'none'" :arrow="indexList.config.length>1?'hover':'none'" :autoplay="indexList.config.length>1" loop>
        <CarouselItem v-for="(item,index) in indexList.config" :key="item.order + index">
          <a :href="item.target" target="_blank"><img :src="item.pic" width="100%" height="100%" /></a>
        </CarouselItem>
      </Carousel>

      <div class="ci-des">
        <div class="ci-des-body">
          <div class="ci-des-body-fontA">数据实时更新</div>
          <div class="ci-des-body-fontB">涵盖中国高校全部<span
            style="color: #1890FF">330多万</span>条专利信息
          </div>
        </div>
        <div class="ci-des-body" style="border-right: 1px solid rgba(255, 255, 255, 0.1)">
          <div class="ci-des-body-fontA">定期更新数据</div>
          <div class="ci-des-body-fontB">拥有中国高校全部<span
            style="color: #1890FF">82万</span>条科技成果信息
          </div>
        </div>
        <div class="ci-des-body" style="border-right: 1px solid rgba(255, 255, 255, 0.1)">
          <div class="ci-des-body-fontA">全行业覆盖</div>
          <div class="ci-des-body-fontB">平台可合作中国高校科研专家<span
            style="color: #1890FF">40万</span>位
          </div>
        </div>
        <div class="ci-des-body">
          <div class="ci-des-body-fontA">平台累计<span
            style="color: #1890FF">153</span>家企业注册
          </div>
          <div class="ci-des-body-fontB">为企业产生可科研匹配<span
            style="color: #1890FF">327</span>次
          </div>
        </div>
      </div>


    </div>
    <demand-information></demand-information>
    <component v-for="(item,index) in indexList.floors" :data="item.config" :is="getComponent(item.config.floorType)"
               :key="index + 1" v-if="item.config.labels && item.config.labels.length > 0"></component>

  </div>
</template>

<script>

import IndexCom from "@/components/IndexCom";
import {
  getIndexCollegeData,
  getIndexFloorDetail,
  getIndexRegisterData,
  getIndexUrl
} from "../../plugins/api/indexApi";
import { formatNum } from "../../plugins/tools/util";
import {queryLisense} from "@/plugins/api/outerApi";
export default {
  name: "ComponentIndex",
  componentList: [],
  components: {
    IndexCom,
    "demandInformation": () => import("@/components/index/homePageComponent/demandAndInformation"),//需求大厅头条资讯
    "enterpriseSchoolCase": () => import("@/components/index/homePageComponent/enterpriseSchoolCase"),//企效通案例
    "hotPatent": () => import("@/components/index/homePageComponent/hotPatent"),//热门专利推荐
    "racePolicy": () => import("@/components/index/homePageComponent/raceAndPolicy"),//科技竞赛科技政策
    "hotService": () => import("@/components/index/homePageComponent/hotService"),//热门服务推荐
    "expertRecommend": () => import("@/components/index/homePageComponent/expertRecommend"),//科研专家推荐
    "testAndNews": () => import("@/components/index/homePageComponent/testAndNews"),//企业体检科技新闻
    "universityStore": () => import("@/components/index/homePageComponent/universityStore"),//高校旗舰店
    "wikiAndReport": () => import("@/components/index/homePageComponent/wikiAndReport"),//知产百科媒体报道
    "enterpriseList": () => import("@/components/index/homePageComponent/enterpriseList")//企业库
    
  },
  data() {
    return {
      value1: 0,
      indexList: {
        config: []
      },//首页数据
      registerData: {},//首页头部注册信息
      collegeData: {},//首页头部学校信息
      footerList: [],//底部跳转链接
      enterpriseListData: {
        config: {
          floorType: "Enterprise_List",
          labels: [
            {id: "1"}
          ],
          name: "企业库"
        }
      },
      lisenseData: [],
      ipcCode: {
        A:'农、林、牧、渔业',
        B:'采矿业',
        C:'制造业',
        D:'电力、热力、燃气及水生产和供应业',
        E:'建筑业',
        F:'批发和零售业',
        G:'交通运输、仓储和邮政业',
        H:'住宿和餐饮业',
        I:'信息传输、软件和信息技术服务业',
        J:'金融业',
        K:'房地产业',
        L:'租赁和商务服务业',
        M:'科学研究和技术服务业',
        N:'水利、环境和公共设施管理业',
        O:'居民服务、修理和其他服务业',
        P:'教育',
        Q:'卫生和社会工作',
        R:'文化、体育和娱乐业',
        S:'公共管理、社会保障和社会组织',
        T:'国际组织'
      }
    };
  },
  mounted() {
    this.getIndexShowDetail();
    this.getRegisterData();
    this.getCollegeData();
  },
  computed: {
    lisenseDataMap() {
      return this.lisenseData.map(x => {
        return {
          name: x.ZLMC,
          price: x.JG ? x.JG + '万' : '面议',
          ans: x.GKGGH,
          classify: this.ipcCode[x.GMJJML],
          pic: require('@/assets/image/lisense-img.png'),
          dataType: '开放许可'
        }
      })
    }
  },
  methods: {
    //获取首页展示数据
    async getIndexShowDetail() {
      const json = await getIndexFloorDetail();
      if (json && json.code === 0) {
        this.indexList = json.result;
        // 写死专家库
        this.indexList.floors.splice(4,0, this.enterpriseListData)
        const result = await  queryLisense({pageNum: 0, pageSize: 10})
        if (result.code === 200) {
          const data = JSON.parse(result.data || '{}').result
          this.lisenseData = data.data || []
          this.indexList.floors[0].config.labels.unshift({
            id: '0',
            name: '开放许可',
            objs: this.lisenseDataMap
          })
        }
      }
    },
    //获取指定的component
    getComponent(type) {
      let component = "";
      const regName = [
        { type: "PATENT_GOODS_FLOOR", component: "hotPatent" },
        // { type: "MAIN_POLICY_FLOOR", component: "racePolicy" },
        { type: "SERVICE_GOODS_FLOOR", component: "hotService" },
        { type: "SHOP_EXPERT_FLOOR", component: "expertRecommend" },
        // { type: "MAIN_NEWS_FLOOR", component: "testAndNews" },
        { type: "SHOP_FLOOR", component: "universityStore" },
        { type: "MAIN_PROPERTY_MEDIA_FLOOR", component: "wikiAndReport" },
        // { type: "Enterprise_List", component: "enterpriseList" }
        // { type: "SINGLE_AD_FLOOR", component: "enterpriseSchoolCase" }
      ];
      regName.map(item => {
        if (type.indexOf(item.type) !== -1) {
          component = item.component;
          return;
        }
      });
      return component;
    },
    async getRegisterData() {
      const json = await getIndexRegisterData();
      if (json && json.code === 0) {
        this.registerData = json.result;
      }
    },
    async getCollegeData() {
      const json = await getIndexCollegeData();
      if (json && json.code === 0) {
        this.collegeData = json.result;
      }
    },
    formatNum(num) {
      return formatNum(num);
    }


  }
};
</script>

<style scoped lang="scss">

.qxt_content .buzhou span {
  font-size: 16px;
  font-family: "Microsoft YaHei";
  color: rgb(255, 255, 255);
  height: 45px;
  line-height: 45px;
  display: inline-block;
  margin-right: 20px;

}


.bottom_second_content span {
  height: 35px;
  line-height: 35px;
  margin-right: 20px;
  font-size: 14px;
  color: rgb(200, 200, 200);
}


.bottom_first_content ul li:hover {
  cursor: pointer;
}

.bottom_first_content ul li {
  display: inline-block !important;
  width: auto;
  height: 75px;
  line-height: 60px;
  margin-right: 50px;
  font-size: 16px;
  font-family: "Microsoft YaHei";
  color: white;
}


.rwjs_sub div {
  margin-bottom: 10px;
}


.rmzl_title_price .desc {
  text-align: left;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;;
  overflow: hidden;
}


.zl_classify span {
  margin-right: 20px;
  line-height: 30px;
}


.xuqiu_content ul, .toutiao_content ul {
  list-style: none;
}

.xuqiu_content ul li {
  display: flex;
  height: 75px;
  line-height: 75px;
}

.toutiao_content ul li {
  height: 50px;
  width: 450px;
  line-height: 50px;
  border-bottom: 1px solid #f5f5f5;
  margin: 0 auto;
  box-sizing: border-box;
}


.tixing div {
  padding-left: 20px;
  color: white;
}

.top-four span {
  margin-left: 80px;
  cursor: pointer;
}

.top-four div:first-child span {
  margin-left: 40px;
}

.top-four span:hover {
  cursor: pointer;
}


.choice span {
  margin-right: 10px;
}

.choice span:hover {
  cursor: pointer;
}


.right_input .input input {
  border-width: 2px;
  border-color: rgb(255, 106, 0);
  border-style: solid;
  background-color: rgb(255, 255, 255);
  width: 700px;
  height: 44px;
}

.right_input .input button {
  background-color: rgb(255, 106, 0);
  width: 130px;
  height: 44px;
  color: white;
  vertical-align: bottom;
  border: none;
}

.input button {
  cursor: pointer;
}

.top-third .content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.top-third .content {
  width: 1200px;
  margin: 0 auto;
  height: 100%;
}

.tab ul {
  list-style: none;
  width: 850px;
}

.tab ul li {
  display: inline-block;
  font-size: 13px;
  font-family: "Microsoft YaHei";
  padding: 0 20px;
  color: rgb(102, 102, 102);
  position: relative;
}

.content .tab {
  width: 850px;
}


.content .platform {
  position: absolute;
  width: 170px;
  font-size: 14px;
  font-family: "Microsoft YaHei";
  color: rgb(102, 102, 102);
}

.content div .border {
  position: absolute;
  left: 93px;
  width: 1px;
  height: 12px;
  background-color: rgb(127, 127, 127);
  top: 14px;
}


.content .zxsc {
  position: absolute;
  left: 190px;
  width: 160px;
}

.ci-header {
  position: relative;
  background-color: #f7f8fa;
}

.ci-des {
  position: absolute;
  bottom: 5px;
  left: 0;
  height: 99px;
  right: 0;
  z-index: 99;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: rgba(51, 51, 51, 0.5);
}

.ci-des-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 25px 30px;
  width: fit-content;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  cursor: pointer;
}

.ci-des-body:hover {
  background-color: rgba(255, 255, 255, 0.12);
}


.ci-des-body-fontA {
  font-size: 18px;
  font-weight: 500;
  color: #FFFFFF;
}

.ci-des-body-fontB {
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
}
</style>
